import Link from "next/link"
import { Flex, Image } from "@chakra-ui/react"

import { CloudImages } from "lib/cosmic/images"

export enum LogoVariant {
  Black,
  White,
}

const Logo = ({ variant = LogoVariant.White }: { variant?: LogoVariant } = { variant: LogoVariant.White }) => {
  return (
    <Link href="/">
      <Flex cursor="pointer">
        <Image src={variant === LogoVariant.White ? CloudImages.LOGO_WHITE : CloudImages.LOGO_BLACK} alt="right arrow" height="35px" />
      </Flex>
    </Link>
  )
}

export default Logo
