import { useEffect } from "react"

import mixpanel from "mixpanel-browser"
import Head from "next/head"
import { Box, Spacer } from "@chakra-ui/react"

import { getMixpanelProjectId } from "lib/clients/mixpanel"
import BingScript from "src/components/Website/BingScript"
import FooterV2 from "src/components/Website/FooterV2"
import HubspotFormScript from "src/components/Website/HubspotFormScript"

import Navbar from "../components/Website/Navbar"

export enum NavbarTheme {
  Dark,
  Light,
}

interface WebsiteLayoutProps {
  title: string
  children: React.ReactNode
  navbarTheme?: NavbarTheme
  navbarOffset?: boolean
}

const WebsiteLayout = ({ title, children, navbarTheme = NavbarTheme.Dark, navbarOffset }: WebsiteLayoutProps) => {
  useEffect(() => {
    mixpanel.init(getMixpanelProjectId(), {
      debug: process.env.NODE_ENV === "development",
      track_pageview: true,
      persistence: "localStorage",
    })
  })

  return (
    <>
      <Head>
        <title>{title}</title>
        <BingScript />
        <HubspotFormScript />
      </Head>

      <Box fontFamily="var(--landing-page-font)">
        {navbarOffset && <Spacer height={{ base: "64px", md: "90px" }} background="var(--website-color-background-base)" />}
        <Box width="90%" margin="auto">
          <Navbar theme={navbarTheme} />
        </Box>

        {children}

        <FooterV2 />
      </Box>
    </>
  )
}

export default WebsiteLayout
