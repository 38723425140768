import Link from "next/link"
import { Flex, Box, Hide, Menu, MenuButton, IconButton, MenuList, MenuItem, Button } from "@chakra-ui/react"
import { FiMenu } from "react-icons/fi"
import { useRouter } from "next/router"

import Logo, { LogoVariant } from "src/components/Website/Logo"
import { NavbarTheme } from "src/layouts/WebsiteLayout"

interface LinkItemProps {
  name: string
  route: string
}

const DesktopLinkItems: Array<LinkItemProps> = [
  { name: "How It Works", route: "/how-it-works" },
  { name: "Why Us", route: "/why-us" },
  { name: "Use Cases", route: "/customer-stories" },
  { name: "Blog", route: "/blog" },
]

const MobileLinkItems: Array<LinkItemProps> = [
  { name: "How It Works", route: "/how-it-works" },
  { name: "Why Us", route: "/why-us" },
  { name: "Use Cases", route: "/customer-stories" },
  { name: "Blog", route: "/blog" },
  { name: "Contact Us", route: "/contact-us" },
]

const NavItem = ({ route, children, ...props }: { route: string; children }) => {
  return (
    <Link href={route}>
      <Flex
        align="center"
        h={["24px", "48px"]}
        p={["0px", "44px 24px"]}
        borderRadius="lg"
        role="group"
        cursor="pointer"
        color="#9296AD"
        fontSize="18px"
        _hover={{
          textDecoration: "underline",
        }}
        {...props}
      >
        {children}
      </Flex>
    </Link>
  )
}

const Navbar = ({ theme }: { theme: NavbarTheme }) => {
  const router = useRouter()

  return (
    <Flex minH="60px" position="absolute" width="100%" top="0px" left="0px" right="0px" justifyContent="space-between" maxWidth="1120px" margin="auto" px={["40px", 0, 0]} py={["16px", 0, 0]}>
      <Flex align="center">
        <Logo variant={theme === NavbarTheme.Dark ? LogoVariant.White : LogoVariant.Black} />
      </Flex>

      <Flex alignItems="center">
        <Box display={{ base: "flex", md: "none" }}>
          <Menu isLazy>
            <MenuButton as={IconButton} aria-label="Options" icon={<FiMenu color={theme === NavbarTheme.Dark ? "white" : "black"} />} size="lg" colorScheme="navy" minWidth="none" />
            <MenuList>
              {MobileLinkItems.map((link) => {
                return (
                  <MenuItem as="a" key={link.name} href={link.route}>
                    {link.name}
                  </MenuItem>
                )
              })}
            </MenuList>
          </Menu>
        </Box>
        <Box display={{ base: "none", md: "flex" }} marginRight="16px">
          {DesktopLinkItems.map((link) => {
            return (
              <NavItem key={link.name} route={link.route}>
                {link.name}
              </NavItem>
            )
          })}
        </Box>
        <Hide below="md">
          <Button
            color="white"
            bg="#6325E3"
            borderRadius="50px"
            _hover={{
              bg: "#6325E3",
            }}
            onClick={() => router.push("/contact-us")}
            fontSize="18px"
          >
            Contact Us
          </Button>
        </Hide>
      </Flex>
    </Flex>
  )
}

export default Navbar
